<template>
  <div class="h-full w-full grid non-scrollable">
    <div class="col-1 mr-0 pr-0 h-full">
      <div v-if="!errorToken && simulation && simulations" class="h-full flex flex-column align-items-center justify-content-start pt-8">
        <div v-for="(simu, index) in simulations" :key="index" :class="simulation.id === simu.id ? 'font-bold' : ''" class="pb-4 cursor-pointer text-xl" @click="changeSimulation(simu)">
          V{{index + 1}}
        </div>
      </div>
    </div>
    <div class="col-11 h-full flex-container non-scrollable">
      <div class="scrollable flex h-full">
        <generated-simulations
            v-if="!errorToken && loaded"
            class="pl-5"
            :settings="settings"
            :idSimulation="idSimulation"
            :simulation="simulation"
            :simulations="simulations"
            showHeader
            @validateSimulation="validateSimulation"
        />
        <div v-else-if="errorToken" class="flex w-full h-full">
          <InlineMessage severity="error" style="margin: auto">{{ $t('errors.invalidToken') }}</InlineMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/utils/Alert';
import {mapGetters, mapState} from 'vuex'
import GeneratedSimulations from "@/components/GeneratedSimulations.vue";

export default {
  name: 'Simulation',
  props: ['idSimulation'],
  components: {GeneratedSimulations},
  data () {
    return {
      sharedSimulations: [],

      errorToken: false,
      loaded: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      settings: state => state.misc.settings
    }),
    ...mapGetters({
      simulation: 'simulation/getSimulation',
      simulations: 'simulation/getSimulations'
    })
  },
  async created () {
    if (this.$route.params.token) {
      this.sharedSimulations = await this.$store.dispatch('simulation/sharedSimulation', { token: this.$route.params.token, simulation: this.$route.params.idSimulation }).catch((e) => {
        Alert.errorMessage(this, e.response.data.message)
        this.errorToken = true
      })
      await this.$store.dispatch('misc/getAllSettings', { token: this.$route.params.token, simulation: this.$route.params.idSimulation })
    } else if (this.$route.params.idSimulation) {
      await this.$store.dispatch('simulation/getSimulation', this.$route.params.idSimulation)
    } else if (this.idSimulation) {
      await this.$store.dispatch('misc/getAllSettings', {})
      await this.$store.dispatch('simulation/getSimulation', this.idSimulation)
    }
    if (!this.errorToken) {
      this.loaded = true
    }
  },
  methods: {
    async changeSimulation (simu) {
      this.$store.state.misc.loading = true

      const foundSharedSimulation = this.sharedSimulations.find((sharedSimulation) => sharedSimulation.simulation.id === simu.id)
      if (foundSharedSimulation) {
        await this.$router.push({
          name: 'ProspectsSimulation',
          params: { idSimulation: simu.id, token: foundSharedSimulation.id}
        })
        await this.$router.go(0)
      }
      this.$store.state.misc.loading = false
    },
    async validateSimulation () {
      // todo: need to "validateSimulation"
      if (this.$route.params.idSimulation) {
        await this.$store.dispatch('simulation/validateSimulation', { id: this.simulation.id, simulations: this.simulations })
        await this.$router.push({ name: 'Register', params: { idInde: this.simulation.independent.id, token: this.$route.params.token} })
      } else {
        // handle new mission
      }
    }
  }
}
</script>

<style>
.router-view {
  height: calc(100vh - 0px) !important;
}

</style>
